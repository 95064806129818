import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { BtnmenuComponent } from './btnmenu.component';

@NgModule({
  declarations: [
      BtnmenuComponent
  ],
  imports     : [
    MatButtonModule,
  ],
  exports     : [
    BtnmenuComponent
  ]
})
export class BtnmenuModule { }
