<button  mat-raised-button  class="coockitbtn" aria-label="Show">
  <img  src="../../../assets/images/CoockitImages/bell-fill.svg"> 
  
  <span >
      Notification
  </span> 
</button>
<button mat-raised-button  class="coockitbtn" aria-label="Show" (click)="recipe()" >
  <img   src="../../../assets/images/CoockitImages/New_recipes.svg"> 
  <span>Add new recipe</span>
</button>
<button mat-raised-button  class=" coockitlogoutbtn" aria-label="Show" (click)="logout()">
  <img  src="../../../assets/images/CoockitImages/box-arrow-in-left.svg"> 
  <span>Logout</span>
  
</button>