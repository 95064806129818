  <div class="mt-7p">
   <p>Review added recipes</p>
   <p>Total: 0</p>
   <button mat-raised-button (click)="openRecipeList()"  class="coockitbtn">
         <span>Show</span>
         
   </button>

   
</div>

