import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { BtnmenuModule } from 'app/main/btnmenu/btnmenu.module';
import { RecipesReviewModule } from 'app/main/RecipesReview/RecipesReview.module';
import { MatButtonModule } from '@angular/material/button';
import { ConfiermDeleteModule } from 'app/main/ConfiermDelete/ConfiermDelete.module';

@NgModule({
    declarations: [
     
    ],
    imports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,

        FuseDirectivesModule,
        FusePipesModule
    ],
    exports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BtnmenuModule,
        FlexLayoutModule,
        RecipesReviewModule,
        FuseDirectivesModule,
        FusePipesModule,
        ConfiermDeleteModule
    ]
})
export class FuseSharedModule
{
}
