import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { getHomePageData, getUserDuration } from 'URL_API';

@Injectable({
  providedIn: 'root'
})
export class HomePageService implements Resolve<any>{

  token: any = sessionStorage.getItem('key');
  option = { headers: { 'Authorization': 'Bearer ' + this.token } };
  AllUsersData: any;
  AllUsersDataChanged: BehaviorSubject<any>;

  AllUserActive: any;
  AllUserActiveChanged: BehaviorSubject<any>;
  constructor(private _httpClient: HttpClient) {
    this.AllUsersDataChanged = new BehaviorSubject({});
    this.AllUserActiveChanged= new BehaviorSubject({});

   }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([
        this.GetAllData()
      ]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }
  GetAllData(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(getHomePageData, this.option)
        .subscribe((response: any) => {
          this.AllUsersData = response.result;
          this.AllUsersDataChanged.next(this.AllUsersData);
          resolve(response.result);
        }, reject);
    });
  }

  GetUserActive(items): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(getUserDuration+items, this.option)
        .subscribe((response: any) => {
          this.AllUserActive = response.result;
          this.AllUserActiveChanged.next(this.AllUserActive);
          resolve(response.result);
        }, reject);
    });
  }
}
