import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { forgetPassword, header, login, ResetPassword, VerifyEmail } from 'URL_API';
import * as CryptoJS from 'crypto-js';
import { JwtHelperService } from '@auth0/angular-jwt';
import { userDetails } from 'app/main/Modules/userDetails';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {
  UserDetails: userDetails;

  constructor(private http: HttpClient,
    private router: Router) {

  }
  login(userForLogin: any) {
    return this.http.post(login, userForLogin, header);
  }
  forgetPassword(userForgetPassword: any) {
    return this.http.post(forgetPassword, userForgetPassword, header);
  }
  VerifyEmail(token) {
    return  this.http.get(VerifyEmail+token,header);
  }
  isloggedIn() {
    try {
      if (sessionStorage.getItem('key')) {
        const token = sessionStorage.getItem('avatar').toString();
        const bytes1 = CryptoJS.AES.decrypt(token, 'gsKxGZ012HLL3MI5');
        const originalText = bytes1.toString(CryptoJS.enc.Utf8);
        const helper = new JwtHelperService();
        const isExpired = helper.isTokenExpired(originalText);
        if (isExpired) {
          this.router.navigate(['/pages/auth/login-2']);
          return false;
        }
        else {
          return true;
        }
      }
      else { return false; }
    }
    catch (error) {
      this.router.navigate(['/pages/auth/login-2']);
      return false;
    }
  }
  currentUrl: string;
  isAdmin() {
    try {
      if (sessionStorage.getItem('key')) {
        const token = sessionStorage.getItem('avatar').toString();
        const bytes1 = CryptoJS.AES.decrypt(token, 'gsKxGZ012HLL3MI5');
        const originalText = bytes1.toString(CryptoJS.enc.Utf8);
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(originalText);
        const isExpired = helper.isTokenExpired(originalText);
        if (isExpired) {
          this.router.navigate(['/pages/auth/login-2']);
          return false;
        }
        else {

          if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] === 'Admin') {
            return true;

          }
          else {
            sessionStorage.removeItem('key');
            return false;

          }
        }
      }
      else { return false; }
    }
    catch (error) {
      this.router.navigate(['/pages/auth/login-2']);
      return false;
    }
  }
  isAdmin1() {
    try {
      if (sessionStorage.getItem('key')) {
        const token = sessionStorage.getItem('avatar').toString();
        const bytes1 = CryptoJS.AES.decrypt(token, 'gsKxGZ012HLL3MI5');
        const originalText = bytes1.toString(CryptoJS.enc.Utf8);
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(originalText);
        const isExpired = helper.isTokenExpired(originalText);
        if (isExpired) {
          this.router.navigate(['/pages/auth/login-2']);
          return false;
        }
        else {
          if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] === 'Admin') {
            return true;

          }
          else {
            return false;

          }
        }
      }
      else { return false; }
    }
    catch (error) {
      this.router.navigate(['/pages/auth/login-2']);
      return false;
    }
  }
  userDetails() {
    if (sessionStorage.getItem('key')) {
      const token = sessionStorage.getItem('avatar').toString();
      const bytes1 = CryptoJS.AES.decrypt(token, 'gsKxGZ012HLL3MI5');
      const originalText = bytes1.toString(CryptoJS.enc.Utf8);
      const helper = new JwtHelperService();
      const decodedToken: any = helper.decodeToken(originalText);
      this.UserDetails = new userDetails(decodedToken.id, decodedToken.firstName, decodedToken.lastName
        , decodedToken.fullName, decodedToken.email, decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
        , decodedToken.created, sessionStorage.getItem('avatar')?.toString(),
        decodedToken.isVerified, decodedToken.PhoneNumber, decodedToken.Location, decodedToken.silaTransaction)
      return this.UserDetails;
    }
    else {
      return;
    }

  }
  logout() {
    sessionStorage.removeItem('key');
    sessionStorage.removeItem('avatar');
    sessionStorage.removeItem('id');
  }
  restPassword(userforRestPassword: any) {
    return this.http.post(ResetPassword, userforRestPassword, header);
}
}
