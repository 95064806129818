import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { SampleComponent } from './sample.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthguardAdminService } from '../pages/authentication/services/AuthguardAdmin.service';
import { HomePageService } from './HomePage.service';

const routes = [
    {
        path     : 'sample',
        component: SampleComponent, canActivate: [AuthguardAdminService],resolve  : {
            profile: HomePageService
        }
    }
];
@NgModule({
    declarations: [
        SampleComponent,


    ],
    imports     : [
        RouterModule.forChild(routes),

        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatOptionModule,
        TranslateModule,
        FuseSharedModule,
        ReactiveFormsModule,
        FuseSharedModule
    ],
    exports     : [
        SampleComponent
    ]
})

export class SampleModule
{
}
