import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfiermDeleteComponent } from './ConfiermDelete.component';
import { MatButtonModule } from '@angular/material/button';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
  imports: [
    MatButtonModule,
    
  ],
  declarations: [ConfiermDeleteComponent],
  exports     : [
    ConfiermDeleteComponent
  ]
})
export class ConfiermDeleteModule { }
