import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServiceService } from '../pages/authentication/services/auth-service.service';

@Component({
  selector: 'app-btnmenu',
  templateUrl: './btnmenu.component.html',
  styleUrls: ['./btnmenu.component.scss']
})
export class BtnmenuComponent implements OnInit {

  constructor(private LoginService: AuthServiceService,private router: Router) { }

  ngOnInit() {
  }
  logout(){
    location.reload();
    this.LoginService.logout();
  }
  recipe(){
    this.router.navigateByUrl('/pages/Recipes');

  }
}
