<div class="page-layout blank p-60" fusePerfectScrollbar>

    <div  fxLayout="column" fxLayoutGap="50px">

        <div fxFlex=25 fxLayout="row" fxLayout.lt-md="column" class="Coockitdiv">
            <div fxFlex=33 class="coockitConent  p-20">
                <p> <img src="../../../assets/images/CoockitImages/users-solid.svg"></p>
                <p>Number Of Users</p>
                <p>{{homePageData.numOfUsers}}</p>

            </div>

            <div fxFlex=33 class="coockitConent  p-20">
                <p> <img src="../../../assets/images/CoockitImages/Recipes.svg"></p>
                <p>Number of recipes</p>
                <p>{{homePageData.numOfRecipes}}</p>

            </div>
            <div fxFlex=34 class="coockitbackground">

            </div>
        </div>
        <div fxFlex=50 fxLayout="row" fxLayout.lt-md="column" class="Coockitdiv">
            <div fxFlex=33 class="coockitConent p-20">
                <p> <img style="vertical-align: middle;" src="../../../assets/images/CoockitImages/users-solid.svg"> Top
                    Users</p>

                <div class="coockitSelect">
                    <mat-form-field class="w-100-p">
                        <mat-label>Duration</mat-label>
                        <mat-select class="coockitSelect" [formControl]="country" (valueChange)="UserDuration()">
                            <mat-option *ngFor="let item of Duration" [value]="item.value">
                                {{ item.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div fxFlex=67 class="Table_background">
                <div class="Coockitdiv coockitConent" fusePerfectScrollbar>
                    <table class="table ">
                        <thead>
                            <tr class="coockitshadow">
                                <th>
                                    User Name
                                </th>
                                <th>
                                    Number of Recipes
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of homePageData.users">
                                <td>
                                    {{item.userName}}
                                </td>
                                <td>
                                    {{item.numberOfRecipes}}
                                </td>
                            </tr> 
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
        <div fxFlex=25 fxLayout="row" fxLayout.lt-md="column" class="" fxLayoutGap="80px">
            <div fxFlex=33 class="Coockitdiv coockitConent  p-20">
                <app-RecipesReview></app-RecipesReview>
            </div>

            <div fxFlex=25 class="Coockitdiv coockitConent  p-20">
                <app-btnmenu></app-btnmenu>
            </div>
            <div fxFlex=33>

            </div>
        </div>

    </div>
</div>