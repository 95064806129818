import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServiceService } from './auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardAdminService {

  constructor(private LoginService: AuthServiceService, private router: Router) { }
    canActivate() {
      if (this.LoginService.isAdmin())
      {
        return true;
      }
     
      this.router.navigate(['/pages/auth/login']);
      return false;
    }

}
