import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RecipesReviewComponent } from './RecipesReview.component';

@NgModule({
  declarations: [
      RecipesReviewComponent
  ],
  imports     : [
    MatButtonModule,
  ],
  exports     : [
    RecipesReviewComponent
  ]
})
export class RecipesReviewModule { }
