export const header = { headers: { 'content-Type': 'application/json' } }

export const Api = 'https://cookitapi.tuqaatech.info';
//export const Api = 'https://api.sila-ex.com';
//export const Api = 'http://192.168.5.129:8080';
/////////////////// Auth /////////////////////////////////////////
export const login = Api + '/api/TokenAuth/Authenticate';
export const forgetPassword = Api + '/api/services/app/Account/ForgetPassword';
export const restPassword = Api + '/api/services/app/Account/ResetNewPassword';
export const VerifyEmail = Api + '/api/services/app/Account/VerifyEmail?token=';
export const ResetPassword = Api + '/api/services/app/Account/ResetNewPassword';
//Categories Api
export const CategoriesApi =  Api + '/api/services/app/CategoriesService/GetAll';
export const DeleteCategoryApi =  Api + '/api/services/app/CategoriesService/Delete?Id=';
export const UpdateCategoryApi =  Api + '/api/services/app/CategoriesService/Update';
export const GetCategoryApi =  Api + '/api/services/app/CategoriesService/Get?Id=';
export const CreateCategoryApi =  Api + '/api/services/app/CategoriesService/Create';
export const CategoryImageApi =  Api +'/api/services/app/CategoriesService/GetDownloadImage?id=';

//CoockBooks Api
export const CookBooksApi =  Api + '/api/services/app/CookBookService/GetAllBooks';
export const DeleteCookBookApi =  Api + '/api/services/app/CookBookService/Delete?Id=';
export const UpdateCookBookApi =  Api + '/api/services/app/CookBookService/Update';
export const GetCookBookApi =  Api + '/api/services/app/CookBookService/Get?Id=';
export const CreateCookBookApi =  Api + '/api/services/app/CookBookService/Create';
export const CookBookImageApi =  Api +'/api/services/app/CookBookService/GetDownloadImage?id=';


//Ingredients Api
export const IngredientsApi =  Api + '/api/services/app/IngredientsService/GetAllIngredients';
export const DeleteIngredientApi =  Api + '/api/services/app/IngredientsService/Delete?Id=';
export const UpdateIngredientApi =  Api + '/api/services/app/IngredientsService/Update';
export const GetIngredientApi =  Api + '/api/services/app/IngredientsService/Get?Id=';
export const CreateIngredientApi =  Api + '/api/services/app/IngredientsService/Create';
export const IngredientImageApi =  Api +'/api/services/app/IngredientsService/GetDownloadImage?id=';

/////////////////////////////////////////////////
export const GetAllUsers = Api + '/api/services/app/User/GetAll';
export const GetUser = Api +'/api/services/app/User/Get?Id=';
export const DeleteUser = Api +'/api/services/app/User/Delete?Id=';
export const DeleteUserDish = Api +'/api/services/app/User/DeleteDish?Id=';
export const CreateUserAdmin = Api +'/api/services/app/User/Create';
export const UpdateUserAdmin = Api +'/api/services/app/User/Update';
export const ChangePassword = Api +'/api/services/app/User/ChangePassword';

//Recipes Api
export const GetAllRecipes = Api + '/api/services/app/Recipe/GetAllRecipes';
export const GetUserRecipe = Api +'/api/services/app/Recipe/GetRecipe?id=';
export const CreateRecipe = Api +'/api/services/app/Recipe/Create';
export const DeleteRecipe = Api +'/api/services/app/Recipe/DeleteAdmin?Id=';
export const UpdateRecipe = Api +'/api/services/app/Recipe/UpdateAdmin';
export const RecipeImageApi =  Api +'/api/services/app/Recipe/GetDownloadImage?id=';
//Countries Api
export const GetCountries = Api + '/api/services/app/CountriesService/GetAll';
//Home Page 
export const getHomePageData = Api + '/api/services/app/User/GetNumberOfUsers';

export const getUserDuration = Api + '/api/services/app/User/GetActuveUsers?UserDuration=';

