import { ThisReceiver } from '@angular/compiler';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { duration } from 'moment';
import { userDetails } from '../Modules/userDetails';
import { AuthServiceService } from '../pages/authentication/services/auth-service.service';
import { HomePageService } from './HomePage.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';

@Component({
    selector   : 'sample',
    templateUrl: './sample.component.html',
    styleUrls  : ['./sample.component.scss']
})
export class SampleComponent
{
    public country: FormControl = new FormControl();
    userDetails :  userDetails;
    homePageData : any;
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */

     Duration = [
        { "name": 'Today', "value": 0 },
        { "name": 'Yesterday', "value": 1 },
        { "name": 'ThisMonth', "value": 2 },
        { "name": 'LastMonth', "value": 3 },
        { "name": 'LastTwoMonth', "value": 4 },
        { "name": 'LastThreeMonth', "value": 5 },
    
    ];
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _authService : AuthServiceService,
        private _homePageService : HomePageService
    )
    {
        this.userDetails = _authService.userDetails();
        this.homePageData=this._homePageService.AllUsersData; 

        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
        this.country.setValue(0);
    }
    UserDuration(){
        this.country.valueChanges.subscribe((value=>{
            this._homePageService.GetUserActive(value)
            this.homePageData.users=this._homePageService.AllUserActive;
        }))
    }
}
