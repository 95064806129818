import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Applications',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
            {
                id       : 'sample',
                title    : 'Sample',
                translate: 'Home',
                type     : 'item',
                icon     : 'home',
                img      : '/assets/icons/SideMenuIcons/Home.svg',
                url      : '/sample'
            }, {
                id       : 'UsersManagement',
                title    : 'UsersManagement',
                translate: 'Users Management',
                type     : 'item',
                icon     : 'supervised_user_circle',
                img      : '/assets/icons/SideMenuIcons/Users-management.svg',
                url      : '/pages/UserManagement'
             
            },  {
                id       : 'Categories',
                title    : 'Categories',
                translate: 'Categories',
                type     : 'item',
                icon     : 'category',
                img      : '/assets/icons/SideMenuIcons/Users-management.svg',
                url      : '/pages/Categories'
             
            },  {
                id       : 'Ingredients',
                title    : 'Ingredients',
                translate: 'Ingredients',
                type     : 'item',
                icon     : 'email',
                img      : '/assets/icons/SideMenuIcons/Users-management.svg',
                url      : '/pages/Ingredients'
             
            },  {
                id       : 'Recipes',
                title    : 'Recipes',
                translate: 'Recipes',
                type     : 'item',
                icon     : 'receipt',
                img      : '/assets/icons/SideMenuIcons/Users-management.svg',
                url      : '/pages/Recipes'
             
            },  {
                id       : 'CookBooks',
                title    : 'CookBooks',
                translate: 'Cook Books',
                type     : 'item',
                icon     : 'book',
                img      : '/assets/icons/SideMenuIcons/Users-management.svg',
                url      : '/pages/CookBooks'
             
            },  {
                id       : 'Profile',
                title    : 'Profile',
                translate: 'Profile',
                type     : 'item',
                icon     : 'supervisor_account',
                img      : '/assets/icons/SideMenuIcons/Users-management.svg',
                url      : '/pages/profile'
             
            }
        ]
    }
];
